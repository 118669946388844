<template>
  <v-dialog v-model="dialog" width="600px">
    <v-card class="border-radius">
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon small>close</v-icon>
        </v-btn>
      </v-card-actions>
      <v-card-text v-show="!newUser.email && !user.id" class="pt-0">
        <div class="mb-5 text-center sw-h4">
          {{ $t("administrator_form.attach_administrator_headline") }}
        </div>
        <v-form data-vv-scope="form-1" @submit.prevent>
          <div class="mx-5 mb-4">
            <v-text-field
              v-model="email"
              v-validate.disable="'required|email'"
              :error-messages="errors.collect('form-1.email')"
              :data-vv-name="'email'"
              :data-vv-as="$tc('email', 2)"
              :label="$tc('email', 1)"
              autofocus
              @keyup.enter="findUser"
            ></v-text-field>
          </div>
          <div class="text-center">
            <v-btn
              round
              class="sw-secondary-bg sw-on-secondary text-none"
              @click="findUser"
              :loading="loading"
              >{{ $t("administrator_form.find_user") }}</v-btn
            >
          </div>
        </v-form>
      </v-card-text>
      <v-card-text v-show="newUser.email" class="px-4">
        <v-form data-vv-scope="form-2" @submit.prevent>
          <div class="mb-5 text-center sw-h4">
            {{ $t("administrator_form.attach_administrator_headline") }}
          </div>
          <v-layout class="row wrap mb-4">
            <v-flex class="xs6 pr-2">
              <v-text-field
                v-model="newUser.first_name"
                v-validate.disable="'required'"
                :error-messages="errors.collect('form-2.first_name')"
                data-vv-name="first_name"
                :data-vv-as="$tc('first-name', 2)"
                :label="$tc('first-name', 1)"
              ></v-text-field>
            </v-flex>
            <v-flex class="xs6 pl-2">
              <v-text-field
                v-model="newUser.last_name"
                v-validate.disable="'required'"
                :error-messages="errors.collect('form-2.last_name')"
                data-vv-name="last_name"
                :data-vv-as="$tc('last-name', 2)"
                :label="$tc('last-name', 1)"
              ></v-text-field>
            </v-flex>
            <v-flex class="xs12">
              <v-text-field
                v-model="newUser.email"
                v-validate.disable="'required|email'"
                :error-messages="errors.collect('form-2.email')"
                data-vv-name="email"
                :data-vv-as="$tc('email', 2)"
                :label="$tc('email', 1)"
                disabled
              ></v-text-field>
            </v-flex>
            <v-flex class="xs12">
              <v-select
                v-model="selectedRoles"
                :items="roles"
                item-text="description"
                item-value="value"
                v-validate.disable="'required'"
                :error-messages="errors.collect('form-2.roles')"
                data-vv-name="roles"
                :label="$tc('roles', 1)"
                multiple
              >
                <template slot="item" slot-scope="data">
                  <span class="slot-item">{{ data.item.description }}</span>
                </template>
              </v-select>
            </v-flex>
          </v-layout>
          <div class="text-center">
            <v-btn
              round
              class="sw-secondary-bg sw-on-secondary text-none"
              @click="createUser"
              :loading="loading"
              >{{ $t("attach") }}</v-btn
            >
          </div>
        </v-form>
      </v-card-text>
      <v-card-text v-if="user.id" class="pt-0 px-4 pb-4">
        <v-form data-vv-scope="form-3" @submit.prevent>
          <div class="mb-5 text-center sw-h4">
            {{ $t("administrator_form.attach_administrator_headline") }}
          </div>
          <div class="mb-1 sw-primary">
            {{ user.first_name }} {{ user.last_name }}
          </div>
          <div class="mb-1 sw-primary">
            {{ user.email }}
          </div>
          <v-flex class="xs12 mb-4">
            <v-select
              v-model="selectedRoles"
              :items="roles"
              item-text="description"
              item-value="value"
              v-validate.disable="'required'"
              :error-messages="errors.collect('form-3.roles')"
              data-vv-name="roles"
              multiple
              :label="$tc('roles', 1)"
            >
              <template slot="item" slot-scope="data">
                <span class="slot-item">{{ data.item.description }}</span>
              </template>
            </v-select>
          </v-flex>
          <div class="text-center">
            <v-btn
              round
              class="sw-secondary-bg sw-on-secondary text-none"
              @click="validateAndCreateAddministrator(user)"
              :loading="loading"
              >{{ $t("attach") }}</v-btn
            >
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    groupId: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    loading: false,
    email: "",
    user: {},
    newUser: {
      email: "",
    },
    roles: [],
    selectedRoles: [],
  }),
  methods: {
    listSupportedRoles() {
      this.loading = true;

      this.$api.groupAdministrators.roles(this.groupId).then(
        (response) => {
          this.loading = false;

          if (!response) return;

          this.roles = response.data.data.map((role) => ({
            description: role.description || role.name,
            value: role.name,
          }));

          if (this.roles && this.roles.length === 1) {
            this.selectedRoles = this.roles.map((el) => el.value);
          }

          if(!this.roles || this.roles.length === 0) {
            this.dialog = false;
            this.$store.dispatch("addErrorNotification", {
              message: this.$t("administrator_form.no_group_roles_enabled"),
            });
          }

        },
        () => {
          this.loading = false;
        },
      );
    },
    openDialog() {
      this.errors.clear();

      this.loading = false;

      this.email = "";
      this.user = {};
      this.newUser = {
        email: "",
      };
      this.selectedRoles = [];

      this.listSupportedRoles();

      this.dialog = true;
    },
    async findUser() {
      const valid = await this.$validator.validateAll("form-1");

      if (!valid) return;

      const params = { email: this.email };

      this.loading = true;

      this.$api.users.search(params).then(
        (response) => {
          this.loading = false;

          if (!response) return;
          if (!response.data.data[0]) {
            this.newUser.email = this.email;
            return;
          }

          this.getGroupAdministrator(response.data.data[0]);
        },
        () => {
          this.loading = false;
        },
      );
    },
    async getGroupAdministrator(model) {
      if (!model) return;

      const params = [this.groupId, model.id];

      this.loading = true;

      this.$api.groupAdministrators.get(...params).then(
        (response) => {
          this.loading = false;

          if (!response) return;

          this.errors.add({
            msg: this.$t("administrator_form.user_already_administrator"),
            scope: "form-1",
            field: "email",
          });
        },
        () => {
          this.loading = false;
          this.user = model;
        },
      );
    },
    async createUser() {
      const valid = await this.$validator.validateAll("form-2");

      if (!valid) return;

      const params = {
        first_name: this.newUser.first_name,
        last_name: this.newUser.last_name,
        email: this.newUser.email,
        password: Math.random()
          .toString(36)
          .substr(2, 8),
      };

      this.loading = true;

      this.$api.users.create(params).then(
        (response) => {
          this.loading = false;

          if (!response) return;

          this.createAddministrator(response.data.data);
        },
        (error) => {
          this.loading = false;
          this.errorMessageShow(error);
        },
      );
    },
    createAddministrator(model) {
      if (!model || !model.id) {
        return;
      }

      const params = [
        this.groupId,
        {
          user_id: model.id,
          roles: this.selectedRoles.toString(),
        },
      ];

      this.loading = true;

      this.$api.groupAdministrators.create(...params).then(
        (response) => {
          this.loading = false;

          if (!response || response.status !== 200) {
            return;
          }

          this.dialog = false;

          this.$emit("success");

          this.$store.dispatch("addNotification", {
            message: this.$t("administrator_form.user_attached", {
              name: `${model.first_name} ${model.last_name}`,
            }),
          });
        },
        (error) => {
          this.loading = false;
          this.errorMessageShow(error);
        },
      );
    },
    async validateAndCreateAddministrator(model) {
      const valid = await this.$validator.validateAll("form-3");

      if (!valid) return;

      this.createAddministrator(model);
    },
  },
};
</script>
