<template>
  <v-container class="general">
    <PageTitle :component="'GroupAdministrators'" />
    <transition name="fade" mode="out-in">
      <v-layout
        v-if="!isLoading && !administrators.length"
        class="row wrap text-center"
      >
        <v-flex class="xs12 sw-h5 font-weight-light">
          {{ $t("administrator_list_page.nothing_created") }}
        </v-flex>
      </v-layout>
    </transition>
    <template v-if="administrators.length">
      <v-layout
        v-for="(administrator, i) in administrators"
        :key="`administrator-${i}`"
        :style="{ width: '100%' }"
        class="row wrap align-center px-4"
      >
        <v-flex class="xs12">
          <v-layout class="row align-center">
            <v-flex class="xs12 py-4">
              <a class="sw-h5 font-weight-light" @click.stop>
                {{ administrator.first_name }}
                {{ administrator.last_name }}
              </a>
              <div class="sw-caption grey--text">
                {{ $tc("email") }}: {{ administrator.email }}
              </div>
            </v-flex>
            <v-btn
              icon
              class="ma-0"
              @click.stop="detachAdministrator(administrator)"
              :loading="isLoading"
            >
              <font-awesome-icon class="sw-accent" icon="trash" />
            </v-btn>
          </v-layout>
        </v-flex>
        <v-flex class="xs12">
          <v-divider></v-divider>
        </v-flex>
      </v-layout>
    </template>
    <AdministratorForm
      ref="administratorForm"
      :group-id="groupId"
      @success="listGroupAdministrators"
    />
    <AppSpeedDial
      @click="addAdministrator"
      :hint="$t('administrator_list_page.speed_dial_hint')"
    />
    <ConfirmationDialog ref="confirmationDialog" />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import AdministratorForm from "@/components/AdministratorListPage/AdministratorForm.vue";

export default {
  data: () => ({
    isLoading: false,
    administrators: [],
  }),
  computed: {
    ...mapGetters(["currentUser"]),
    groupId() {
      return this.$route.params.group_id;
    },
    isAdmin() {
      if (!this.currentUser || !this.currentUser.roles) {
        return false;
      }

      return this.currentUser.roles.some((r) => r === "admin");
    },
  },
  components: {
    AdministratorForm,
  },
  mounted() {
    this.listGroupAdministrators();
  },
  methods: {
    addAdministrator() {
      this.$refs.administratorForm.openDialog();
    },
    listGroupAdministrators() {
      this.isLoading = true;

      this.$api.groupAdministrators.list(this.groupId).then(
        (response) => {
          this.isLoading = false;

          if (!response) return;

          this.administrators = response.data.data;
        },
        () => {
          this.isLoading = false;
        },
      );
    },
    async detachAdministrator(model) {
      if (!model || !model.id) {
        return;
      }

      const confirmed = await this.$refs.confirmationDialog.open(
        this.$t("administrator_list_page.administrator_delete_confirmation_text", {
          name: `${model.first_name} ${model.last_name}`,
        }),
        this.$t("no"),
        this.$t("yes"),
      );

      if (!confirmed) return;

      const params = [this.groupId, model.id];

      this.isLoading = true;

      this.$api.groupAdministrators.delete(...params).then(
        (response) => {
          this.isLoading = false;

          if (!response || response.status !== 200) {
            return;
          }

          this.listGroupAdministrators();

          this.$store.dispatch("addNotification", {
            message: this.$t("administrator_list_page.administrator_delete_success_notification", {
              name: `${model.first_name} ${model.last_name}`,
            }),
          });
        },
        (error) => {
          this.isLoading = false;
          this.errorMessageShow(error);
        },
      );
    },
  },
};
</script>
